import axios from './axiosInit';

const getList = async () =>
{
    try
    {
        let url = 'dashboard'
        // let role = JSON.parse(localStorage.getItem('userData'));
        // if(role.role == 'boat_owner'){
        //    url = url + '&id=' + role.id
        // }
        const result = await axios.get(url)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}
export default {
    getList

}
