<template>
    <div>
        <v-card flat
                class="rounded-lg border-card">
            <v-card-text>
                <v-avatar color="#e8e8e8"
                          :size="card.size"
                          class="pa-4 rounded-lg">
                    <img :src="card.imge">
                </v-avatar>
            </v-card-text>


            <v-card-text class="mt-n1">
                <span class="text-caption text-black">{{ $t(card.text) }}</span>
            </v-card-text>

            <v-card-text class="mt-n2">
                <span class="font-weight-bold text-h6 text-black"
                      v-if="card.format"> {{ convertNumber(card.value) }}</span>
                <span class="font-weight-bold text-h6 text-black"
                      v-if="!card.format"> {{ card.value }}</span>
            </v-card-text>

            <!-- <v-card-text class="mt-n4 text-caption">
                <img :src="card.img" class="mr-2"><span :style="{ color: card.color }">{{ card.percent }}</span>
               <span class="ml-2 text-black">{{ card.month }}</span>
            </v-card-text> -->
        </v-card>
    </div>
</template>

<script>

import decimalCurrency from '@/@core/utils/decimalCurrency';
export default {
    props: {
        card: {
            type: Object,
            default: '',
        }
    },
    methods: {
        convertNumber(value)
        {
            return decimalCurrency.convertNumber(value);
        }
    }
}
</script>

<style scoped>
.text-black {
    color: #000;
}

.radius {
    border-radius: 190px;
}</style>