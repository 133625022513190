
export const convertNumber = (value, curr) =>
{

    let num = parseFloat(value);
    if (curr)
    {
        return num.toLocaleString("en-" + curr, { style: "currency", currency: curr });
    }
    else
    {
        return num.toLocaleString("en-SA", { style: "currency", currency: "SAR" });
    }

}

export default {
    convertNumber
}