<template>
    <div>
        <div class="mx-0 d-flex pb-2 align-center justify-space-between">
            <p style="font-size: 20px;"
               class="font-weight-semibold text-black">
                {{ $t('Dashboard') }}
            </p>
        </div>
        <v-divider class="mt-n1 pb-5"></v-divider>

        <v-row>
            <v-col cols="12"
                   lg="3"
                   md="3"
                   sm="4"
                   v-for="card in cards"
                   :key="card.icon">
                <card-content :card="card"></card-content>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   lg="3"
                   md="3"
                   sm="4"
                   v-for="card in cards1"
                   :key="card.icon">
                <card-content :card="card"></card-content>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   md="6">
                <v-card>
                    <v-card-text class="d-flex justify-space-between align-center flex-wrap pb-5">
                        <div>
                            <span class="font-weight-semibold text-base text-black">
                                {{ $t('Top 10 App Users') }}
                            </span>
                        </div>

                    </v-card-text>


                    <v-data-table :headers="headers"
                                  :items="rows"
                                  hide-default-footer
                                  :loading="loading"
                                  class="my-data-table">
                        <template v-slot:header.name="{ header }">
                            {{ header.text }}
                            <v-icon size="20">mdi-menu-swap</v-icon>
                        </template>
                        <template v-slot:header.value="{ header }">
                            {{ header.text }}
                            <v-icon size="20">mdi-menu-swap</v-icon>
                        </template>
                        <template v-slot:header.bookings="{ header }">
                            {{ header.text }}
                            <v-icon size="20">mdi-menu-swap</v-icon>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span class="text-black font-size font-weight-semibold">{{ item.name }}</span>
                            <div class="font-weight"> {{ item.name1 }}</div>
                        </template>
                        <template v-slot:item.value="{ item }">
                            <span class="text-black font-size">{{ convertNumber(item.value) }}</span>
                        </template>
                        <template v-slot:item.bookings="{ item }">
                            <span class="text-black font-size">{{ item.bookings }}</span>
                        </template>
                    </v-data-table>

                </v-card>
            </v-col>

            <v-col cols="12"
                   md="6">
                <v-card>
                    <v-card-text class="d-flex justify-space-between align-center flex-wrap pb-5">
                        <div>
                            <span class="font-weight-semibold text-base text-black">
                                {{ $t('Top 10 Frequently Used Boats') }}
                            </span>
                        </div>
                    </v-card-text>


                    <v-data-table :headers="headings"
                                  :items="boats"
                                  hide-default-footer
                                  :loading="loading"
                                  class="my-data-table">
                        <template v-slot:header.name="{ header }">
                            {{ header.text }}
                            <v-icon size="20">mdi-menu-swap</v-icon>
                        </template>
                        <template v-slot:header.value="{ header }">
                            {{ header.text }}
                            <v-icon size="20">mdi-menu-swap</v-icon>
                        </template>
                        <template v-slot:header.bookings="{ header }">
                            {{ header.text }}
                            <v-icon size="20">mdi-menu-swap</v-icon>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span class="text-black font-size font-weight-semibold">{{ item.name }}</span>
                        </template>
                        <template v-slot:item.value="{ item }">
                            <span class="text-black font-size">{{ convertNumber(item.value) }}</span>
                        </template>
                        <template v-slot:item.bookings="{ item }">
                            <span class="text-black font-size">{{ item.bookings }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import dashboardService from '@/services/dashboard-services';
import CardContent from '@/views/dashboard/CardContent.vue';
import { useUtils } from '@core/libs/i18n';
import decimalCurrency from '@/@core/utils/decimalCurrency';

export default {
    components: {
        CardContent
    },
    data()
    {
        return {
            cards: [

            ],
            cards1: [

            ],
            headers: [
                { text: 'User Name', value: 'name', sortable: false, align: 'start' },
                { text: 'Booking Value', value: 'value', sortable: false, align: 'center' },
                { text: 'Total Bookings', value: 'bookings', sortable: false, align: 'center' },
            ],
            headings: [
                { text: 'Boat Name', value: 'name', sortable: false, align: 'start' },
                { text: 'Value', value: 'value', sortable: false, align: 'center' },
                { text: 'Booking Count', value: 'bookings', sortable: false, align: 'center' },
            ],
            rows: [

            ],
            boats: [

            ],
            loading: false
        }
    },
    mounted()
    {

        dashboardService.getList().then(resp =>
        {
            // console.log(resp);
            if (resp.statusCode == 200)
            {
                this.cards = [
                    { imge: require('@/assets/images/svg/card-icon1.svg'), icon2: 'mdi-dots-horizontal', text: 'Total Number of Bookings', format: false, value: resp.data.dashboard.all_bookings_count ? resp.data.dashboard.all_bookings_count : 0, img: require('@/assets/images/character.png'), percent: '13.02%', month: 'From May', color: '#16C098', size: '25' },
                    { imge: require('@/assets/images/svg/card-icon2.svg'), icon2: 'mdi-dots-horizontal', text: 'Total Bookings Value', format: true, value: resp.data.dashboard.all_bookings_amount ? resp.data.dashboard.all_bookings_amount.toFixed(2) : 0, img: require('@/assets/images/error.png'), percent: '0.32%', month: 'From May', color: '#FF0000', size: '25' },
                    { imge: require('@/assets/images/svg/card-icon3.svg'), icon2: 'mdi-dots-horizontal', text: 'Total No. of Active Boats', format: false, value: resp.data.dashboard.active_boats_count ? resp.data.dashboard.active_boats_count : 0, img: require('@/assets/images/character.png'), percent: '3.02%', month: 'From May', color: '#16C098', size: '25' },
                    { imge: require('@/assets/images/svg/card-icon4.svg'), icon2: 'mdi-dots-horizontal', text: 'No. of Boats Pending Approval', format: false, value: resp.data.dashboard.pending_boats_count ? resp.data.dashboard.pending_boats_count : 0, img: '', percent: '', month: '', size: '25' },
                ]
                this.cards1 = [
                    { imge: require('@/assets/images/svg/card-icon5.svg'), icon2: 'mdi-dots-horizontal', text: 'Completed Orders', format: false, value: resp.data.dashboard.completed_bookings_count ? resp.data.dashboard.completed_bookings_count : 0, img: require('@/assets/images/character.png'), percent: '13.02%', month: 'From May', color: '#16C098', size: '25' },
                    { imge: require('@/assets/images/svg/card-icon6.svg'), icon2: 'mdi-dots-horizontal', text: 'New Orders', format: false, value: resp.data.dashboard.new_bookings_count ? resp.data.dashboard.new_bookings_count : 0, img: require('@/assets/images/error.png'), percent: '0.32%', month: 'From May', color: '#FF0000', size: '25' },
                    { imge: require('@/assets/images/svg/card-icon7.svg'), icon2: 'mdi-dots-horizontal', text: 'Pending Orders', format: false, value: resp.data.dashboard.pending_bookings_count ? resp.data.dashboard.pending_bookings_count : 0, img: require('@/assets/images/character.png'), percent: '3.02%', month: 'From May', color: '#16C098', size: '27' },
                    { imge: require('@/assets/images/svg/card-icon8.svg'), icon2: 'mdi-dots-horizontal', text: 'Rejected Orders', format: false, value: resp.data.dashboard.cancelled_bookings_count ? resp.data.dashboard.cancelled_bookings_count : 0, img: require('@/assets/images/character.png'), percent: '3.02%', month: 'From May', color: '#16C098', height: '32px', size: '27' },
                ]

                if (resp.data.frequentUsers)
                {
                    resp.data.frequentUsers.forEach(element =>
                    {
                        this.rows.push({
                            name: element?.user?.first_name,
                            name1: element?.user?.email,
                            value: element?.all_bookings_amount?.toFixed(2),
                            bookings: element?.all_bookings_count
                        })
                    });
                }

                if (resp.data.frequentBoats)
                {
                    resp.data.frequentBoats.forEach(element =>
                    {
                        this.boats.push({
                            name: element.boat.boat_name,
                            value: element.all_bookings_amount.toFixed(2),
                            bookings: element.all_bookings_count
                        })
                    });
                }
            }
        })
    },
    methods: {
        convertNumber(value)
        {
            return decimalCurrency.convertNumber(value);
        },
    },
    setup()
    {
        const { t } = useUtils()

        return {
            // i18n
            t
        }

    }

}
</script>

<style lang="scss" scoped>
.text-black {
    color: #000;
}

.font-weight {
    font-size: 10px;
}

.bg {
    background-color: #fff;
}

.v-divider {
    border-color: #FFFFFF !important;
}

.v-expansion-panel {
    box-shadow: 0px 0px 28px 2px #0000001A !important;
}

.v-application.theme--light .v-expansion-panel.v-expansion-panel--active {
    box-shadow: 0px 0px 28px 2px #0000001A !important;
}

.my-data-table ::v-deep .v-data-table-header th {
    font-size: 12px;
    font-weight: 450;
    height: 40px;
}

.font-size {
    font-size: 14px;
    font-weight: 400;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
    font-size: 0.875rem;
    height: 3.125rem;
    // border-left: 1px solid #efeded !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    // color: #131C24 !important;
    background: #fff !important;
    border: none;
    border-bottom: 1px solid #efeded;
}

::v-deep .v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    border-bottom: thin solid rgba(94, 86, 105, 0.14);
    border-right: 1px solid #efeded;
}
</style>
