var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mx-0 d-flex pb-2 align-center justify-space-between"},[_c('p',{staticClass:"font-weight-semibold text-black",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.$t('Dashboard'))+" ")])]),_c('v-divider',{staticClass:"mt-n1 pb-5"}),_c('v-row',_vm._l((_vm.cards),function(card){return _c('v-col',{key:card.icon,attrs:{"cols":"12","lg":"3","md":"3","sm":"4"}},[_c('card-content',{attrs:{"card":card}})],1)}),1),_c('v-row',_vm._l((_vm.cards1),function(card){return _c('v-col',{key:card.icon,attrs:{"cols":"12","lg":"3","md":"3","sm":"4"}},[_c('card-content',{attrs:{"card":card}})],1)}),1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-text',{staticClass:"d-flex justify-space-between align-center flex-wrap pb-5"},[_c('div',[_c('span',{staticClass:"font-weight-semibold text-base text-black"},[_vm._v(" "+_vm._s(_vm.$t('Top 10 App Users'))+" ")])])]),_c('v-data-table',{staticClass:"my-data-table",attrs:{"headers":_vm.headers,"items":_vm.rows,"hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-menu-swap")])]}},{key:"header.value",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-menu-swap")])]}},{key:"header.bookings",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-menu-swap")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-black font-size font-weight-semibold"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"font-weight"},[_vm._v(" "+_vm._s(item.name1))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-black font-size"},[_vm._v(_vm._s(_vm.convertNumber(item.value)))])]}},{key:"item.bookings",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-black font-size"},[_vm._v(_vm._s(item.bookings))])]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-text',{staticClass:"d-flex justify-space-between align-center flex-wrap pb-5"},[_c('div',[_c('span',{staticClass:"font-weight-semibold text-base text-black"},[_vm._v(" "+_vm._s(_vm.$t('Top 10 Frequently Used Boats'))+" ")])])]),_c('v-data-table',{staticClass:"my-data-table",attrs:{"headers":_vm.headings,"items":_vm.boats,"hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-menu-swap")])]}},{key:"header.value",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-menu-swap")])]}},{key:"header.bookings",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-menu-swap")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-black font-size font-weight-semibold"},[_vm._v(_vm._s(item.name))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-black font-size"},[_vm._v(_vm._s(_vm.convertNumber(item.value)))])]}},{key:"item.bookings",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-black font-size"},[_vm._v(_vm._s(item.bookings))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }